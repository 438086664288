/* audio css */

.bg-music{
  position: absolute;
  z-index: 50;
  bottom: 3%;
  right: 5%;
  cursor: pointer;
  transition: .5s ease-in-out;
}

/* snowfall animation css */

.snowfall{
  position: absolute;
  inset: 0;
  z-index: 1;
  height: 90vh;
  overflow: hidden;
}

.snowflake{
  --small:40px;
  --medium:57px;
  position: absolute;
  top: 0;
  opacity: 0;
  border-radius: 50%;
  transition: .5s ease-in-out;
  animation: fall 8s linear infinite
  /* background-color: white; */
  /* width: var(--small);
  height: var(--small); */
}

/* .snowflake:nth-child(2n+3){
  width: var(--medium);
  height: var(--medium);
} */

.flake-icon{
  width: 100%;
  height: 100%;
}

/* deer animation */

.deer-icon{
  position: absolute;
  bottom: 15%;
  left: 38%;
  height: 300px;
  width: 500px;
  object-fit: contain;
  animation-delay: 1s;
  opacity: 0;
  animation: deer-move 10s linear infinite;
}

/* moon css */

.moon-icon{
  position: absolute;
  top: -60px;
  left: 38%;
  height: 700px;
  width: 500px;
  object-fit: contain;
  transition: .5s ease-in-out;
}

/* tree css */

/* new year css */

.new-year-container{
  height: 90vh;
}

.eve-img-container{
  overflow: hidden;
  height: 60%;
  background-color: #345261;
  transition: .5s ease-in-out;
}
.year-img{
  bottom: -70px;
  height: auto;
  width: 60%;
  object-fit: contain;
  transition: .5s ease-in-out;
}
.eve-img{
  bottom: -20px;
  height: auto;
  width: 100%;
  object-fit: contain;
  transition: .5s ease-in-out;
}
.star-img{
  top: 0;
  height: auto;
  width: 100%;
  object-fit: contain;
  transition: .5s ease-in-out;
}

.text-container{
  height: 40%;
  transition: .5s ease-in-out;
}

@media screen and (min-width:1900px) {
  .year-img{
    bottom: -130px;
  }
}
@media screen and (min-width:1700px) {
  .year-img{
    bottom: -100px;
  }
}

@media screen and (max-width:1200px) {
  .eve-img-container{
    height: 50%;
  }
  .year-img{
    bottom: -50px;
  }
  .eve-img{
    height: 100%;
    bottom: -35px;
  }
  .star-img{
    top: 25px;
  }
}

@media screen and (max-width:800px) {
  .eve-img-container{
    height: 40%;
  }
  .star-img{
    top: 40px;
  }
  .year-img{
    width: 70%;
    bottom: -40px;
  }
}
@media screen and (max-width:600px) {
  .eve-img-container{
    height: 40%;
  }
  .eve-img{
    bottom: 0px;
    object-fit: cover;
  }
  .star-img{
    object-fit: cover;
  }
  .year-img{
    width: 90%;
    bottom: -30px;
  }
  .text-container{
    height: 60%;
  }
}




.tree-icon{
  position: absolute;
  bottom: -30%;
  left: 0%;
  overflow: hidden;
  transition: .5s ease-in-out;
}
.tree{
  height: 5%;
}

@media screen and (max-width:1100px) {
  .tree-icon{
    bottom: -15%;
  }
  .moon-icon{
    left: 28%;
  }
}
@media screen and (max-width:850px) {
  .tree-icon{
    bottom: -1%;
  }
  .moon-icon{
    left: 20%;
  }
}

@media screen and (max-width:600px) {
  .tree-icon{
    bottom: -1%;
  }
  .moon-icon{
    left: 10%;
  }
  .tree{
    height: 35%;
  }
  .deer-icon{
    height: 300px;
    width: 300px;
  }
 
}

@media screen and (max-width:450px) {
  .tree-icon{
    bottom: -1%;
  }
  .bg-music{
    bottom: 1%;
  }
}


@keyframes deer-move{
  0%{
    left: 0%;
    opacity: 1;
    bottom: 0%;
    transform: scale(1);
  }50%{
    opacity: 1;
    bottom: 15%;
  }
  100%{
    bottom: 25%;
    left: 100%;
    opacity: 0;
    transform: scale(.5);
  }
}



@keyframes fall{
  0% {
    transform: translateY(-100px);
    opacity: 1;
    transition: .5s ease-in-out;
  }100%{
    opacity: 0;
    transform: translateY(90vh);
  }
}

.sver:hover {
  border: 2px solid #345261;
}


.card-title {
  text-align: center;
  color: #345261;

}

.card {
  overflow: hidden;
  transition: transform 0.3s ease-out;
  text-align: justify;

}

.cardhover:hover {
  transform: scale(1.1);
 

}
.ss{
  margin-top: 30px;
}
.text1 {
  font-weight: bold;

  color: #345261;
  margin-top: 10%;
  width: 100%;
}
.sv {
  font-weight: bold;
  /* font-size: 55px; */
  color: #345261;
  margin-top: 10%;
  width: 100%;
}

.text2 {
  font-weight: bold;
  /* font-size: 55px; */
  color: #345261;
  margin-top: 35%;
  margin-bottom: 15%;

}

.text5 {
  color: #345261;
  text-align: justify;
  -moz-text-align-last: start;
  text-align-last: start;
  font-size: 1rem;
}

@media (min-width: 320px) {
  .text5 {
    font-size: 0.3rem;
  }
}

@media (min-width: 768px) {
  .text5 {
    font-size: 0.7rem;
  }
}

@media (min-width: 1200px) {
  .text5 {
    font-size: 1rem;
    margin-top: 3%;
  }
}

.text3 {
  font-weight: bold;
  font-size: 4rem;
  color: #345261;
  text-align: justify;
  -moz-text-align-last: start;
  text-align-last: start;
}

@media (min-width: 320px) {
  .text3 {
    font-size: 2rem;
  }
}

@media (min-width: 768px) {
  .text3 {
    font-size: 4rem;
  }
}

@media (min-width: 992px) {
  .text3 {
    font-size: 6rem;
  }
}

@media (min-width: 1200px) {
  .text3 {
    font-size: 8rem;
  }
}

.verticalline {
  height: 25rem;
}

@media (min-width: 320px) {
  .verticalline {
    height: 10rem;
  }
}

@media (min-width: 768px) {
  .verticalline {
    height: 20rem;
  }
}

@media (min-width: 1200px) {
  .verticalline {
    height: 30rem;
  }
}

.img1 {
  height: 7rem;
  width: 7rem;
}

@media (min-width: 320px) {
  .img1 {
    height: 4rem;
    width: 4rem;
  }
}

@media (min-width: 768px) {
  .img1 {
    height: 6rem;
    width: 6rem;
  }
}

@media (min-width: 1200px) {
  .img1 {
    height: 7rem;
    width: 7rem;
  }
}

.img2 {
  margin-bottom: auto;
}







.text6 {
  font-size: 80px;
  color: #345261;
  position: relative;
  margin-top: 30%;

}


.text7 {
  text-align: end;
  color: #345261;

}

.text8 {
  font-size: 12px;
  color: black;
  margin-top: 1%;

}

/* .text9 {
    font-size: 80px;
    color: #345261;
  } */


.text11 {
  text-align: start;
  color: #345261;

height: 20px;
}


.card {
  margin: 0;

  box-shadow: 0px 10px 20px -5px #adadad;

}

.card-img-zoom {
  overflow: hidden;
}

.card-img-zoom img {
  transition: transform 0.3s ease-in-out;
  width: 100%;
  height: auto;

}

.contactss{
  border: 1px solid #aaa;
  border-radius: 30px;

}
.card-img-zoom:hover img {
  transform: scale(1.1);
}



.text13 {
  color: #345261;
  position: absolute;
  left: 25vh;
  top: 60%;
  transform: translate(-30%, -70%);


}

.bg {
  height: 300px;

}

/* .text16{
    text-align: center;
    color: #345261;
    font-size: 80px;
  margin-top: 10%;
  } */
.text15 {
  margin-top: 30%;
  text-align: center;
  justify-content: center;

}

.text17 {
  justify-content: center;
  text-align: center;
  color: #345261;
  font-weight: bold;
  font-size: 70px;
 top: 50%;

}

.input1 {
  margin-top: 30%;
}

#buttons {
  color: white;
  width: 150px;
  height: 50px;
}

/* contacts css */
/*   END forms styling  */