.menu {
  /* font-size: 16px; */
  margin-right: 20px;
  font-size: auto !important;

}

/* .nav-links {
  color: #345261; 
  transition: color 0.3s ease, border-bottom 0.3s ease;
  border-bottom: 2px solid transparent;
  padding-bottom: 5px;
  font-size: auto;

}

.nav-links.active {
  border-bottom: 2px solid #345261;
  font-weight: bold;
  font-size: auto;
} */

.nav-link.active {
  font-weight: bold;
}
.nav-link {
  position: relative;
  display: inline-block;
}

.nav-link.active::after {
  content: '';
  position: absolute;
  bottom: -5px; /* Adjust this value as needed */
  left: 50%;
  transform: translateX(-50%);
  width: 12px; /* Size of the circle dot */
  height: 12px; /* Size of the circle dot */
  background-color: #345261; /* Circle color */
  border-radius: 50%;
  font-size: bold !important;
}

.Spangles-active::after {
  background-color: white !important; /* Circle color for home page active state */
  font-size: bold;
}
@media screen and (max-width:1200px){
  .nav-menu-cont{
    display: flex;
    flex-direction: column;
    gap: 10px;
    width:100%;
    justify-content: center;
    align-items: center;
  }
  .nav-link.active::after{
    content:"";
    position:absolute;
    bottom: 5px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%; /* Size of the circle dot */
    height: 2px; /* Size of the circle dot */
    background-color: #345261; /* Circle color */
    /* border-radius: 50%; */
    font-size: bold !important;
    transition:.5s ease-in-out
  }
}

