body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.event-icon-wrapper {
  position: fixed;
  bottom: 30px;
  right: 50px;
  z-index: 100;
  display: flex;
  flex-direction: column; 
  align-items: center;
}


.register-text {
  color: rgb(216, 203, 203);
  /* margin-top: 10px; */
  font-weight: 600;
  font-size: larger;
  transition: opacity 0.3s ease;
  animation: bounce 1.5s infinite ease-in-out;
  transition: width 0.3s ease, transform 0.3s ease;
}

.register-text-hidden {
  opacity: 0;
}

.event-icon-container {
  position: fixed;
  bottom: 80px;
  right: 60px;
  z-index: 100;
  display: flex; 
  align-items: center;
  justify-content: center;
  /* background-color: rgb(240, 234, 234); */
  min-width: 100px; 
  min-height: 100px;
  max-width: 200px; 
  max-height: 200px;
  /* padding: 10px;  */
  /* border-radius: 20%; */

  
}

.event-icon {
  /* width: 80px; */
  /* height: 80px; */
  border-radius: 50%;
  /* background-color: rgb(223, 210, 210); */
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  animation: bounce 1.5s infinite ease-in-out;
  transition: width 0.3s ease, transform 0.3s ease;
  transform-origin: right center;
  white-space: nowrap; 
}

.event-icon-expanded {
  /* width: 180px; */
  height: 80px;
  max-width: 240px; /* Equal width and height */
  /* max-height: 240px; */
  /* border-radius: 10%; */
  border:1px solid white;
  width: auto; 
  /* padding: 10px 25px;  */
  /* gap: 10px; */
  margin-left: 10px;
  margin-right: 10px;

  transform: translateX(0);
  animation: none;
  justify-content: center;
}

.event-icon-text {
  text-align: center;
  font-weight: 500;
  width: 100%;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-8px); 
  }
  60% {
    transform: translateY(-4px); 
  }
}

@media (max-width: 600px) {
  .event-icon-container {
    bottom: 10px;
    right: 10px;
  }
}

@media (max-width: 600px) {
  .event-icon-wrapper {
      bottom: 50px;
      right: 60px;
  }
}