.gallery-section {
  display: flex;
  flex-direction: column;
  gap: 5rem;
  padding: 100px;
  transition: .5s ease-in-out;
}
.Gallery{
  position: relative;
  transition: .5s ease-in-out;
}

.gallery-title {
  font-size: 48px;
  font-weight: bold;
  color: #345261;
  text-align: center;
  transition: .5s ease-in-out;
}

.masonry-layout-cont {
  column-count: 3;
  row-gap: 10px;
  column-gap: 10px;
}

.masonry-item{
  height: 100%;
  width: 100%;
}
 .gallery-video{
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 10px;
  min-height: 200px;
}
.react-player__preview{
   min-height: 400px;
}


.gallery-image{
  width: 100%;
  margin-bottom: 10px;
  border-radius: 15px;
  transition: .5s ease-in-out;
}
iframe{
  border-radius: 10px;
  width: 100%;
}
.gallery-modal{
  position: absolute;
  top: 30%;
  left: 45%;
  opacity: 0;
  width: 0px;
  height: 0px;
  transform: scale(0);
  background-color: grey;
  transition: .5s ease-in-out;
  padding: 10px;

}

.gallery-modal-open{
  opacity: 1;
  width: 100px;
  height: 100px;
  transform: scale(1);
}


@media (max-width: 1024px) {
  .gallery-section{
    padding: 50px;
  }
  .gallery-title {
    font-size: 40px; 
    padding: 0 20px; 
  }

  .masonry-layout-cont {
    column-count: 2;
    column-gap: 10px; 
  }

  .gallery-image {
    margin-bottom: 10px;
  }
}

@media (max-width: 600px) {
  .masonry-layout-cont{
    column-count: 1;
  }
  .gallery-section{
    padding: 30px;
    gap: 2rem;
  }
}

@media (max-width: 300px) {
  .gallery-section{
    padding: 20px;
  }
}

