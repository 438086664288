.sidebar {
    background-color: #f8f9fa;
    height: 100vh;
    padding: 30px 20px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    overflow: scroll;
}

.sidebar-close-button {
    background: none;
    border: none;
    color: #333;
    font-size: 18px;
    cursor: pointer;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 5px;
}

.sidebar-close-button img {
    width: 20px;
    height: 20px;
}

.sidebar-content h2 {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
}


.application-form {
    width: 100%;
    max-width: 600px; 
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 20px; 
}

.form-group {
    position: relative;
    display: flex;
    flex-direction: column;
}

.form-group label {
    position: absolute;
    top: -10px;
    left: 0;
    /* background-color: #fff; */
    /* padding: 0 5px; */
    font-size: 14px;
    color: #6c757d;
    margin-top: 20px;
}

.form-group input{
    border-bottom: 1px solid #adb5bd;
    display: block;
    text-indent: 160px;


}
.form-group input,
.form-group select {
    width: 100%;
    border: none;
    border-bottom: 1px solid #adb5bd;
    outline: none;
    padding: 10px 0;
    background-color: transparent;
    transition: border-color 0.3s ease;
    text-indent: 160px;
}

.select-container {
    position: relative;
    height: 400px;
  }
  
  select {
    width: 100%;
    max-height: 200px; 
    overflow-y: auto;  
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    appearance: none;  
  }
  
  .select-container::after {
    content: "▼";
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none; 
  }

  .redstar{
    color:red;
  }
  

  .form-group option {
    max-height: 200px; 
    overflow-y: hidden;  
    display: block;     
    width: 100%;        
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
  }
  

.form-group input:focus,
.form-group select:focus {
    border-bottom: 2px solid #007bff;
}

.submit-button {
    background-color: #34495e;
    color: #fff;
    padding: 12px 0;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    width: 100%;
    font-weight: 600;
    letter-spacing: 0.5px;
    transition: background 0.3s ease;
}

.submit-button:hover {
    background-color: #2c3e50;
}


